html {
  font-size: 10px;

  font-family: "Inter", sans-serif !important;
}

.css-ahj2mt-MuiTypography-root {
  font-family: "Inter", sans-serif !important;
}

.App {
  text-align: center;
}

header {
  height: 72px;
}

.main-header {
  background-image: linear-gradient(
    90deg,
    rgb(255 242 234) 23%,
    rgba(255, 255, 255, 1) 24%
  );
}

.headerLogo {
  height: 40px;

  width: 200px;
}

.nav {
  background-image: url("./Image/navBackground.svg");

  background-repeat: no-repeat;

  background-size: 100% 72px;

  background-position: -380px;

  color: black;

  height: 100%;

  z-index: 1;

  /* width:100% */
}

/* code for mobile screen */

@media only screen and (max-width: 720px) {
  .nav {
    background-image: none;
  }
}

@media only screen and (max-width: 1450px) {
  .nav {
    background-size: 100% 72px;

    background-position: -380px;
  }
}

@media only screen and (max-width: 800px) {
  html {
    font-size: 8px;
  }

  .headerLogo {
    width: 140px;

    height: 30px;
  }

  .nav {
    background-size: 58% 50px;
  }
}

@media only screen and (max-width: 395px) {
  html {
    font-size: 6px;
  }

  .headerLogo {
    width: 120px;

    height: 28px;
  }
}

/* code for footer */

.footer {
  background-color: #ebebf1;

  padding: 40px;

  margin-top: auto;

  display: flex;

  justify-content: center;

  text-align: start;

  /* padding-bottom: "px"; */
}

.row {
  display: flex;

  flex-wrap: wrap;

  justify-content: space-between;

  /* margin-left: 50px; */

  padding-bottom: 10px;
}

.column {
  margin-right: 10px;

  /* flex-basis: calc(50% - 0.5rem); */
}

@media (max-width: 1200px) {
  .column {
    margin-left: 2rem;
  }
}

@media (max-width: 720px) {
  .column {
    flex-basis: 100%;

    margin-top: 2rem;

    padding: 10px;

    margin-left: 0px !important;
  }

  .footer-address {
    display: none;
  }

  .main-header {
    background-image: none;
  }
}

.link {
  text-decoration: none;

  color: #46466d;

  font-size: 14px;
}

.bottomRow {
  background-color: #0e0e3d;

  color: white;

  text-align: center;

  padding: 16px;
}

.columnImage {
  max-width: 100%;
}

.text {
  text-align: start;

  font-weight: 400;
}

.section-wrapper {
  padding: 8rem 0 !important;

  margin-top: 0 !important;
}

.googleplay {
  margin-top: 5px;

  width: 50%;

  background-color: transparent;
}

.column2 {
  display: grid;
}

.toolText {
  display: flex;
}

.icon {
  display: flex;

  margin: 6px;
}

.sub-header {
  height: 56px !important;

  top: 72px !important;

  border-top: 1px solid #f2f2f2;

  min-height: 56px;
}

.sub-header .css-1yo9slo-MuiToolbar-root {
  min-height: 56px;

  padding: 0px;
}

.sub-header button {
  font-size: 15px;
}

.sub-header button:last-child {
  font-size: 14px;
}

.sub-header button svg {
  margin-left: 0px;
}

.home-slider {
  margin-top: 16vh;
}

.home-slider .css-1b01k47-MuiPaper-root-MuiMobileStepper-root {
  position: relative;

  margin-top: -26px;

  background: transparent;
}

.section-wrapper .heading {
  letter-spacing: 0px;

  line-height: 54px;

  margin-bottom: 10px;

  /* font-size: 50px; */

  text-align: left;

  font-weight: 800;

  color: #171971;
}

.join-slider {
  margin: 2rem;

  max-width: 1134px;
}

.slider-images .css-3glx1 {
  vertical-align: middle;

  height: 390px;

  padding-left: 74px;
}

.join-slider button {
  border-radius: 8px !important;

  padding: 8px 24px !important;

  text-transform: capitalize !important;

  font-weight: 700 !important;
}

.join-slider .css-6yocjr {
  width: 100%;
}

.footer .css-15djyu2-MuiTypography-root {
  font-family: "Inter", sans-serif !important;

  letter-spacing: 0px;
}

.footer .css-1wlyyw1-MuiTypography-root,
.footer .css-b1k3lv-MuiTypography-root {
  font-family: "Inter", sans-serif !important;

  font-size: 14px;

  font-weight: 400;
}

.footer .css-xslj9p-MuiTypography-root {
  font-family: "Inter", sans-serif !important;

  font-size: 14px;
}

.footer .css-1b9an32-MuiTypography-root {
  font-family: "Inter", sans-serif !important;

  font-size: 14px;

  letter-spacing: 0px;
}

.bottomRow p {
  font-size: 12px !important;
}

@media (max-width: 720px) {
  .home-slider {
    margin-top: 7vh;
  }
}

.privacyPolicy h3 {
  font-size: 1.5rem;
}
.privacyPolicy p {
  font-size: 1.3rem;
}
.privacyPolicy ul li {
  font-size: 1.3rem;
}
.privacyPolicy h4 {
  font-size: 1.3rem;
}
