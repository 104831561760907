body {
  margin: 0;
  font-family: "Inter", sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.sevenPayAdvantage {
  /* background-image: url("./Image/sevenpayAdvantageBackground.png"); */
  /* background-image: url("./Image/BestOfSevenPayBack.png"); */

  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-color: "#F6F6F6";
  height: 100%;
  width: 100vw;
}

.BestPartSevenPay {
  background-image: url("./Image/BestOfSevenPayBack.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-color: "##F6F6F6";
  /* minHeight: 40vh; */
}

@media only screen and (max-width: 720px) {
  .sevenPayAdvantage {
    background-image: none;
  }
  .BestPartSevenPay {
    height: 100%;
  }
}
